import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Escreva seu nome completo')
    .max(50, 'Nome muito grande. Por favor, reduza um pouco.')
    .required('Campo obrigatório'),

  email: Yup.string()
    .required('Campo obrigatório')
    .email('Digite um email válido'),

  whatsapp: Yup.string()
    .typeError('Apenas números')
    .min(11, 'Mínimo de 11 digitos.')
    .required('Campo obrigtório'),
})
