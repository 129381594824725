import React, { useEffect, useState } from 'react'
import SubNavbar from '../../components/subnavbar'
import img from '../../_assets/img/subnovbar/galeria.png'
import './index.css'
import AppServices from '../../services/index'
import { useParams } from 'react-router-dom'

const Novelty = () => {
    const [novelty, setNovelty] = useState([])
    const params = useParams()
    var striptags = require('striptags');

    useEffect(() => {
        AppServices()
            .getNovelty(params.slug)
            .then((res) => {
                setNovelty(res.data.data)
                console.log(res.data.data)
            })
    }, [])

    return (
        <div>
            <SubNavbar imgNavbar={img} title='Notícias' />
            <div className='container mx-auto mb-8'>
                <div className='mx-[90px]'>
                    <img src={`${process.env.REACT_APP_STORAGE_SERVER}/${novelty.src}`} style={{maxWidth: '600px'}}/>
                    <h3 className='font-bold text-2xl tracking-wide'>
                        {novelty.title}
                    </h3>
                    <p
                        className='text-xl'
                        style={{
                            whiteSpace: 'pre-wrap',
                            textAlign: 'justify',
                        }}
                    >
                        {striptags(novelty.text, [], '\n')}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Novelty
