import './_assets/css/App.css'
import './_assets/css/tailwind.css'
import './_assets/css/fonts.css'
import 'flowbite'
import Navbar from './components/navbar/index'
import Body from './containers/Route'
import Footer from './components/footer/index'
function App() {
    return (
        <div>
            <Navbar />
            <Body />
            <Footer />
        </div>
    )
}

export default App
