import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Escreva seu nome completo')
    .max(50, 'Nome muito grande. Por favor, reduza um pouco.')
    .required('Campo obrigatório'),

  email: Yup.string()
    .required('Campo obrigatório')
    .email('Digite um email válido'),

  message: Yup.string()
    .min(10, 'Mensagem muito curta.')
    .max(500, 'Mensagem muito longa.')
    .required('Campo obrigatório'),
})
