import React, { useEffect, useState } from 'react'
import SubNavbar from '../../components/subnavbar'
import img from '../../_assets/img/subnovbar/midias.jpg'
import NewsCard from '../../components/cards/news'
import Nextbutton from '../../_assets/icons/NextButton.svg'
import previusButton from '../../_assets/icons/PreviousButton.svg'
import AppServices from '../../services/index'

const News = () => {
  const [dataList, setDataList] = useState([])
  const [news, setNews] = useState([])
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [perPage, setPerPage] = useState(6)
  const [disableNext, setDisableNext] = useState(false)
  const [disablePrev, setDisablePrev] = useState(true)

  useEffect(() => {
    AppServices()
      .getNews()
      .then((res) => {
        setNews(res.data.data.data)
        console.log(res.data.data.data)
      })
  }, [])

  useEffect(() => {
    setDataList(news)
  }, [news])

  useEffect(() => {
    setCount(Math.ceil(dataList.length / perPage))
  }, [dataList, perPage])

  useEffect(() => {
    if (page === count || (page === 1 && count === 0)) {
      setDisableNext(true)
    } else {
      setDisableNext(false)
    }

    if (page === 1) {
      setDisablePrev(true)
    } else {
      setDisablePrev(false)
    }
  }, [count, page, disableNext, disablePrev])

  const paginate = (array, perPage, page) => {
    return array.slice((page - 1) * perPage, page * perPage)
  }

  const handleNext = () => {
    setPage(page + 1)
  }

  const handlePrev = () => {
    setPage(page - 1)
  }

  const dateFormater = (date) => {
    var months = [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ]

    const dt = new Date(date)

    var day = dt.getDate()
    // get month from 0 to 11
    var month = dt.getMonth()
    // conver month digit to month name
    month = months[month]
    var year = dt.getFullYear()

    // show date in two digits
    if (day < 10) {
      day = '0' + day
    }

    // now we have day, month and year
    // arrange them in the format we want
    return day + ', ' + month + ' ' + year
  }

  return (
    <div>
      <SubNavbar imgNavbar={img} title='Notícias' />
      <div className='container mx-auto mb-8'>
        <div className='flex flex-col mx-[18px] md:grid md:grid-cols-3 gap-4 '>
          {/* {news.map((news, index) => (
            <div
              className='mx-auto cursor-pointer'
              key={index}
              onClick={() => (window.location.href = news.url)}
            >
              <NewsCard
                img={news.src}
                tag={news.tag}
                title={news.title}
                description={news.description}
                date={dateFormater(news.date)}
                url={news.url}
              />
            </div>
          ))} */}

          {paginate(dataList, perPage, page).map((news, index) => (
            <div
              className='mx-auto cursor-pointer'
              key={index}
            >
              <NewsCard
                img={news.src}
                tag={news.tag}
                title={news.title}
                description={news.description}
                date={dateFormater(news.date)}
                url={news.url}
              />
            </div>
          ))}
        </div>
      </div>
      <div className='container mx-auto w-full flex flex-row justify-end mb-4'>
        <button
          className={`${
            !disablePrev ? 'bg-[#4FB3F9]' : 'bg-[#ECF2FE]'
          } mx-[10px]`}
          onClick={() => handlePrev()}
          disabled={disablePrev}
        >
          <div
            className={`${disablePrev ? 'text-[#4FB3F9]' : 'text-[#ECF2FE]'}`}
            alt=''
          >
            <svg
              width='56'
              height='56'
              viewBox='0 0 56 56'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M36 27H23.83L29.42 21.41L28 20L20 28L28 36L29.41 34.59L23.83 29H36V27Z'
                fill={`${disablePrev ? '#4FB3F9' : '#ECF2FE'}`}
              />
            </svg>
          </div>
        </button>
        <button
          className={`${
            !disableNext ? 'bg-[#4FB3F9]' : 'bg-[#ECF2FE]'
          } mx-[10px]`}
          onClick={() => handleNext()}
          disabled={disableNext}
        >
          <svg
            width='56'
            height='56'
            viewBox='0 0 56 56'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M28 20L26.59 21.41L32.17 27H20V29H32.17L26.59 34.59L28 36L36 28L28 20Z'
              fill={`${disableNext ? '#4FB3F9' : '#ECF2FE'}`}
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default News
