import React, { useEffect, useState } from 'react'
import SubNavbar from '../../components/subnavbar'
import img from '../../_assets/img/subnovbar/midias.jpg'
import ProposalsCard from '../../components/cards/proposals.js'
import book_page from '../../_assets/icons/book_page.svg'
import saude from '../../_assets/icons/saude.svg'
import seguranca from '../../_assets/icons/seguranca.svg'
import turismo from '../../_assets/icons/mapa.svg'
import desenvolvimento from '../../_assets/icons/carrinho.svg'
import competitividade from '../../_assets/icons/setinha.svg'
import Nextbutton from '../../_assets/icons/NextButton.svg'
import previusButton from '../../_assets/icons/PreviousButton.svg'
import download from '../../_assets/icons/download.svg'

import axios from 'axios'

import pdf from '../../_assets/pdf/plano-de-governo-2023-2026.pdf'

import AppServices from '../../services'

const Proposals = () => {
    const [dataList, setDataList] = useState([])
    const [proporsals, setProporsals] = useState([])
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const [perPage, setPerPage] = useState(1000)
    const [disableNext, setDisableNext] = useState(true)
    const [disablePrev, setDisablePrev] = useState(true)

    useEffect(() => {
        AppServices()
            .getProporsals()
            .then((res) => {
                setProporsals(res.data.data.data)
            })
    }, [])

    useEffect(() => {
        setDataList(proporsals)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proporsals])

    useEffect(() => {
        setCount(Math.ceil(dataList.length / perPage))
    }, [dataList, perPage])

    useEffect(() => {
        console.log(page)
        if (page === count || (page === 1 && count === 0)) {
            setDisableNext(true)
        } else {
            setDisableNext(false)
        }

        if (page === 1) {
            setDisablePrev(true)
        } else {
            setDisablePrev(false)
        }
    }, [count, page, disableNext, disablePrev])

    const paginate = (array, perPage, page) => {
        return array.slice((page - 1) * perPage, page * perPage)
    }

    const handleNext = () => {
        setPage(page + 1)
    }

    const handlePrev = () => {
        setPage(page - 1)
    }

    const downloadPdf = () => {
        const link = document.createElement('a')
        link.href = pdf
        link.setAttribute('download', 'plano-de-governo-2023-2026.pdf')
        link.click()
        document.body.removeChild(link)
    }

    return (
        <div>
            <SubNavbar imgNavbar={img} title='Propostas' />
            <div className='container mx-auto'>
                <div className='mx-auto grid  xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 gap-4 mt-4'>
                    {paginate(dataList, perPage, page).map(
                        (proporsal, index) => (
                            <div key={index}>
                                {
                                    proporsal.titleEmphasis === 'SAÚDE' &&
                                    <ProposalsCard
                                        color='#701BB0'
                                        widthButton='56px'
                                        heightButton='56px'
                                        width='33px'
                                        heigth='28px'
                                        titleStart={proporsal.titleStart}
                                        titleFinish={proporsal.titleFinish}
                                        titleEmphasis={proporsal.titleEmphasis}
                                        description={proporsal.description}
                                        logo={saude}
                                    />
                                }
                                {
                                    proporsal.titleEmphasis === 'EDUCAÇÃO' &&
                                    <ProposalsCard
                                        color='#701BB0'
                                        widthButton='56px'
                                        heightButton='56px'
                                        width='38px'
                                        heigth='33px'
                                        titleStart={proporsal.titleStart}
                                        titleFinish={proporsal.titleFinish}
                                        titleEmphasis={proporsal.titleEmphasis}
                                        description={proporsal.description}
                                        logo={book_page}
                                    />
                                }
                                {
                                    proporsal.titleEmphasis === 'SEGURANÇA' &&
                                    <ProposalsCard
                                        color='#701BB0'
                                        widthButton='56px'
                                        heightButton='56px'
                                        width='26px'
                                        heigth='21px'
                                        titleStart={proporsal.titleStart}
                                        titleFinish={proporsal.titleFinish}
                                        titleEmphasis={proporsal.titleEmphasis}
                                        description={proporsal.description}
                                        logo={seguranca}
                                    />
                                }
                                 {
                                    proporsal.titleEmphasis === 'TURISMO' &&
                                    <ProposalsCard
                                        color='#701BB0'
                                        widthButton='56px'
                                        heightButton='56px'
                                        width='26px'
                                        heigth='21px'
                                        titleStart={proporsal.titleStart}
                                        titleFinish={proporsal.titleFinish}
                                        titleEmphasis={proporsal.titleEmphasis}
                                        description={proporsal.description}
                                        logo={turismo}
                                    />
                                }
                                {
                                    proporsal.titleEmphasis === 'DESENVOLVIMENTO ECONÔMICO' &&
                                    <ProposalsCard
                                        color='#701BB0'
                                        widthButton='56px'
                                        heightButton='56px'
                                        width='30px'
                                        heigth='25px'
                                        titleStart={proporsal.titleStart}
                                        titleFinish={proporsal.titleFinish}
                                        titleEmphasis={proporsal.titleEmphasis}
                                        description={proporsal.description}
                                        logo={desenvolvimento}
                                    />
                                }
                                {
                                    proporsal.titleEmphasis === 'INOVAÇÃO E COMPETITIVIDADE' &&
                                    <ProposalsCard
                                        color='#701BB0'
                                        widthButton='56px'
                                        heightButton='56px'
                                        width='36px'
                                        heigth='31px'
                                        titleStart={proporsal.titleStart}
                                        titleFinish={proporsal.titleFinish}
                                        titleEmphasis={proporsal.titleEmphasis}
                                        description={proporsal.description}
                                        logo={competitividade}
                                    />
                                }
                            </div>
                        )
                    )}
                </div>

                <div className='w-full flex flex-row justify-center'>
                    <button
                        type='button'
                        className='sora mb-[23px] text-white bg-[#FF0000] focus:outline-none focus:ring-4 focus:ring-red-300  rounded-full text-sm my-2 px-7 py-5 text-center  dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 text-[18px] '
                        onClick={downloadPdf}
                    >
                        Baixe o programa do Governo
                        <img
                            className='ml-2'
                            src={download}
                            style={{ display: 'inline' }}
                            alt='baixar programa do governo'
                        />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Proposals
