import React from 'react'

const subNavbar = (props) => {
    return (
        <div className='flex items-end mb-[50px] relative'>
            <img
                className='w-full min-h-[168px] '
                src={props.imgNavbar}
                alt=''
            />
            <div
                className={`absolute flex flex-row left-[50px] min-w-[78px] h-[38px] md:min-w-[173px] md:h-[62px] items-center justify-center'
                `}
                style={{
                    backgroundColor: props.color ? props.color : '#F4901E',
                }}
            >
                    <p 
                        style={{
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '18px',
                            color: '#FFF',
                            textAlign: 'center',
                            width: '100%'
                        }}
                        className='text-[14px] md:text-[26px]'
                    >
                        {props.title ? props.title : 'Title props'}
                    </p>
            </div>
        </div>
    )
}
export default subNavbar
