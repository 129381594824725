import React from 'react'
import CTAWhatsapp from '../../_assets/img/whatsapp/CTA.png'
import CTAWhatsappMobile from '../../_assets/img/whatsapp/Whatsapp.png'
import arrowRight from '../../_assets/icons/arrowRight.svg'
import './whatsapp.css'

const whatsappCTA = () => {
  return (
    <div
      className='lg:mt-[85px] lg:mx-[65px] cursor-pointer'
    >
      <div style={{position: 'relative', top: 0, left: 0}}>
        <img
          className='hidden xl:block lg:block w-full '
          src={CTAWhatsapp}
          alt=''
        />
        <a href="https://api.whatsapp.com/send?phone=5582999570515" target={'_blank'}>
          <div id="inputDesktopMessage" className='hidden xl:block lg:block'>
          </div>
        </a>
      </div>
      <div>
        <a href="https://api.whatsapp.com/send?phone=5582999570515" target={'_blank'}>
          <img
            className='block xl:hidden lg:hidden w-full '
            src={CTAWhatsappMobile}
            alt=''
          />
        </a>
      </div>
    </div>
    
  )
}
export default whatsappCTA
