import { React } from 'react'
import 'flowbite'
import compartilhar from '../../_assets/icons/compartilhar.svg'
const NewsCard = (props) => {
    const capitalize = (s) => {
        const str = s.toLowerCase()
        return str[0].toUpperCase() + str.slice(1)
    }

    return (
        <div className='max-w-sm bg-white'>
            <a href={props.url} target="_blank">
                <img
                    className='rounded-[5px]'
                    src={`${process.env.REACT_APP_STORAGE_SERVER}/${props.img}`}
                    alt=''
                />
            <div className='p-5'>
                <p className='text-[18px] sora-extra-bold'>{capitalize(props.tag)}</p>

                <h5
                    className='tusker_grotesk_6500_medium mb-2 text-[#701bb0] text-[24px] leading-[40px] font-bold tracking-tight dark:text-white'
                >
                    {props.title}
                </h5>

                <div className='mb-3 sora-400 text-[16px] text-[#666666]'>
                    {props.description}
                </div>
                <div className='grid grid-cols-6 gap-4'>
                    <div className='col-start-1 col-end-5 text-[16px] sora-400 text-[#666666]'>
                        <p>{props.date}</p>
                    </div>
                    <div className='col-end-7'>
                        <img
                            className='rounded-[2px]'
                            src={compartilhar}
                        />
                    </div>
                </div>
            </div>
            </a>
        </div>
    )
}
export default NewsCard
