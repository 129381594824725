import React from 'react';
import play from '../../_assets/icons/play_circle_filled_white.svg'
const videoThumb = () =>{
    return (      
        <div role="status" className="sm:w-54' md:w-221 lg:w-488 bg-cover bg-center flex justify-center items-center w-[100%] h-[500px] bg-gray-300 bg-[url(../../_assets/img/video/thumb.png)] rounded-lg dark:bg-gray-700 mb-7">
            <img src={play}></img>           
            <span className="sr-only">Loading...</span>
        </div>
    )
}
export default videoThumb;