
import { React, Component } from 'react';
import './proposals.css'
import 'flowbite'

const ProposalsCard = (props) =>{
        var striptags = require('striptags')

        return (
            <div className="p-6 max-w-sm bg-white mx-auto">
                <button className="buttonSvgSocial" style={{backgroundColor: props.color, width: props.widthButton, height: props.heightButton}} alt="Instagram dantas">
                    <img id="instagram" className="iconSvgSocial " style={{width: props.width, height: props.height}} src={props.logo}/>
                </button>
                <a href="#">
                    <h5 id='titleproposta' className="leading-[29px] mb-5 text-[32px]  mt-[18px] max-w-[100%] tusker_grotesk_6500_medium">{props.titleStart} <span className='text-[32px]  text-[#F4901E]'> {props.titleEmphasis}</span> {props.titleFinish}</h5>
                </a>
                
                <div className="mb-3 font-normal text-[#666666] sora" style={{
                                    whiteSpace: 'pre-wrap',
                                }}>{striptags(props.description, [], '\n')}</div>

            </div>

        )
}
export default ProposalsCard;
