import React from "react";
import Banner from "../../components/banner";
import GalleryCardIcon from "../../components/GalleryCardIcon";
import Social from "../../components/social";
import VideoThumb from "../../components/videoThumb";
// import InstagramFeed from '../../components/instagram'
import WhatsappCTA from "../../components/whatsapp";
const Home = () => {
  return (
    <div>
      <Banner className='w-full min-h-[300px] max-h-[850px]' />
      <div className='w-full mx-auto overflow-hidden'>
        <div className='container mx-auto'>
          <GalleryCardIcon className='' />
        </div>
        <div className='container my-[75px] hidden xl:block lg:block mx-auto'>
          <Social
            color='#F4901E'
            widthButton='62px'
            heightButton='62px'
            widthInstagram='31px'
            heightInstagram='31px'
            widthFacebook='18.6px'
            heightFacebook='35.65px'
            widthTwitter='30.38px'
            heightTwitter='24.18px'
            widthYoutube='34.1px'
            heightYoutube='23.25px'
          />
        </div>
        <div className='container mx-auto'>
          <a target='_blank' href='https://youtu.be/hUd8N32oh2w'>
            <VideoThumb />
          </a>
        </div>
        {/* <InstagramFeed /> */}
        <div className='container mx-auto'>
          <WhatsappCTA />
        </div>
      </div>
    </div>
  );
};

export default Home;
