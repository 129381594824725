import { React, Component } from 'react'
import 'flowbite'
import logo from '../../_assets/img/logo/logo.svg'
import Social from '../../components/social'

class Footer extends Component {
    render() {
        return (
            <footer className='w-full shadow md:px-6 md:py-8 dark:bg-gray-900 bg-[#701BB5]'>
                <div className='sm:flex sm:items-center justify-center mb-6'>
                    <a href='/' className='flex items-center mb-4 sm:mb-0'>
                        <img
                            src={logo}
                            className='mx-auto mt-[34px] h-[65px]'
                            alt='Dantas Logo'
                        />
                    </a>
                </div>
                <div className='sm:flex sm:items-center justify-center'>
                    <ul className=' flex flex-wrap items-center mb-6 text-sm text-white sm:mb-0 dark:text-gray-400'>
                        <li>
                            <a
                                href='/noticias'
                                className='hidden xl:block lg:block mr-4 hover-menu md:mr-6 '
                            >
                                Notícias
                            </a>
                        </li>
                        <li>
                            <a
                                href='/tocomopaulo'
                                className='hidden xl:block lg:block mr-4 hover-menu md:mr-6'
                            >
                                Tô com o Paulo
                            </a>
                        </li>
                        <li>
                            <a
                                href='/proposta'
                                className='hidden xl:block lg:block mr-4 hover-menu md:mr-6 '
                            >
                                Propostas
                            </a>
                        </li>
                        <li>
                        {/* <a
                                href='/oqueelefez'
                                className='hidden xl:block lg:block mr-4 hover-menu md:mr-6 '
                            >
                                Biografia
                            </a> */}
                        </li>
                        <li>
                        <a
                            href='/historia'
                            className='hidden xl:block lg:block mr-4 hover-menu md:mr-6 '
                            >
                              História
                            </a> 
                        </li>
                        <li>
                        <a
                                href='/galeria'
                                className='hidden xl:block lg:block hover-menu md:mr-6'
                            >
                                Galeria de imagens
                            </a> 
                        </li>
                        <li>
                            <a
                                href='/faleconosco'
                                className='hidden xl:block lg:block mr-4 hover-menu md:mr-6 '
                            >
                                Fale conosco
                            </a>
                        </li>
                        <li>
                            <a
                                href='/midias'
                                className='hidden xl:block lg:block hover-menu'
                            >
                                Mídias
                            </a>
                        </li>
                    </ul>
                </div>
                <div className=' sm:flex sm:items-center justify-center'>
                    <div className='mt-8'>
                        <Social
                            color='#8D49C4'
                            widthButton='34px'
                            heightButton='34px'
                            widthInstagram='17px'
                            heightInstagram='17px'
                            widthFacebook='10.2px'
                            heightFacebook='19.55px'
                            widthTwitter='16.66px'
                            heightTwitter='13.26px'
                            widthYoutube='18.7px'
                            heightYoutube='12.75px'
                        />
                    </div>
                </div>

                <hr className='container my-4 border-[#AC8BFF] sm:mx-auto ' />
                <span className='text-[#AC8BFF] block text-sm text-white text-center dark:text-gray-400'>
                Propaganda Eleitoral | Coligação “ALAGOAS DAQUI PRA MELHOR” (MDB / Federação Brasil da Esperança - FE BRASIL (PT/PC do B/PV) / PDT / PSC / SOLIDARIEDADE) CNPJ 47.571.253/0001-89
                </span>
            </footer>
        )
    }
}

export default Footer
