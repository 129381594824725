import React, { useEffect, useState } from 'react'
import SubNavbar from '../../components/subnavbar'
import img from '../../_assets/img/subnovbar/galeria.png'
import './index.css'
import AppServices from '../../services/index'

const History = () => {
    const [history, setHistory] = useState([])
    var striptags = require('striptags')

    useEffect(() => {
        AppServices()
            .getHistory()
            .then((res) => {
                setHistory(res.data.data.data)
            })
    }, [])

    const breakLine = (string) => {
        const text = string.replace(/(?:\r\n|\r|\n)/g, '<br>')
        return text
    }

    return (
        <div>
            <SubNavbar imgNavbar={img} title='História' />
            <div className='md:container md:mx-auto mb-8'>
                <div className='mx-[20px] md:mx-[90px]'>
                    {history.map((history, index) => (
                        <div key={index}>
                            <h3 className='font-bold text-2xl tracking-wide  mb-8'>
                                {history.title}
                            </h3>
                            <p
                                className='text-xl'
                                style={{
                                    whiteSpace: 'pre-wrap',
                                    textAlign: 'justify',
                                }}
                            >
                                {striptags(history.text, [], '\n')}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default History
