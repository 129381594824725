import React, { useEffect, useState } from 'react'
import fotoConjunto from '../../_assets/img/history/conjunto.png'
import paulonogoverno from '../../_assets/img/history/paulonogoverno.png'
import nadapara from '../../_assets/img/history/nadapara.png'
import degoverno from '../../_assets/img/history/degoverno.png'
import iconradio from '../../_assets/icons/radio.svg'
import iconradio2 from '../../_assets/icons/radio2.svg'
import iconeseta from '../../_assets/icons/iconseta.svg'
import AppServices from '../../services/index'
import './timeline.css'

import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'

const Timeline = () => {
    const [timeline, setTimeline] = useState([])

    useEffect(() => {
        AppServices()
            .getTimeLine()
            .then((res) => {
                setTimeline(Object.entries(res.data.data))
                // console.log(res.data.data)
                console.log(Object.entries(res.data.data))
            })
    }, [])

    return (
        <div className='flex flex-col items-center justify-start pt-[120px]'>
            {timeline.map((item, index) => (
                <div className={`hidden md:flex flex-col items-center`}>
                    <span
                        className={`${
                            index % 2 === 0 ? 'ml-[-28px]' : 'mr-[-65px]'
                        } flex justify-center items-center w-6 h-6 my-[10px] rounded-full ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900`}
                    >
                        <div
                            className={`mr-[10px] ${
                                index % 2 === 0 ? '' : 'hidden'
                            }`}
                        >
                            {item[0]}
                        </div>

                        <img
                            className={`${
                                index === item.length - 1 ? '' : 'hidden'
                            }`}
                            src={iconradio2}
                            alt=''
                        />
                        <img
                            className={`${
                                index === item.length - 1 ? 'hidden' : ''
                            }`}
                            src={iconradio}
                            alt=''
                        />
                        <div
                            className={`ml-[10px] ${
                                index % 2 === 0 ? 'hidden' : ''
                            }`}
                        >
                            {item[0]}
                        </div>
                    </span>

                    <div className='grid grid-cols-2 gap-4 justify-items-center'>
                        {item[1]
                            .sort((a, b) => a.id - b.id)
                            .map((img, imgIndex) => (
                                <div
                                    className={`${
                                        index === item.length - 1
                                            ? 'border-[#999999] border-solid'
                                            : 'border-[#701BB5] border-solid'
                                    } ${
                                        imgIndex % 2 === 0 ||
                                        index === item.length
                                            ? 'mt-[-30px] px-[50px] '
                                            : 'border-l-[3px] p-[30px]'
                                    } w-full content-center flex flex-row items-center `}
                                >
                                    <img
                                        className='w-full max-w-[400px]'
                                        src={`${process.env.REACT_APP_STORAGE_SERVER}/${img.src}`}
                                        style={{ padding: '5px' }}
                                        alt=''
                                    />
                                </div>
                            ))}
                    </div>
                </div>
            ))}

            {timeline.map((item, index) => (
                <div className='flex md:hidden flex-col items-center'>
                    <div
                        style={{
                            display: index === 0 ? 'none' : '',
                            width: '0px',
                            height: '33px',
                            left: '188px',
                            top: '424px',

                            border: '2px solid #701BB5',
                        }}
                    ></div>
                    <span
                        className={`mr-[35px] my-[10px] flex justify-center items-center w-6 h-6 rounded-full ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900`}
                    >
                        <div>{item[0]}</div>
                        <img className='mb-2' src={iconradio} alt='' />
                    </span>
                    <div
                        style={{
                            width: '0px',
                            height: '33px',
                            left: '188px',
                            top: '424px',

                            border: '2px solid #701BB5',
                        }}
                    ></div>

                    <div className='flex flex-col gap-4 justify-items-start overflow-hidden '>
                        {item[1].map((img, imgIndex) => (
                            <div className='mx-auto content-center'>
                                <img
                                    src={`${process.env.REACT_APP_STORAGE_SERVER}/${img.src}`}
                                    style={{ padding: '5px' }}
                                    alt=''
                                />
                            </div>
                        ))}
                    </div>
                </div>
            ))}

            <div className='mx-auto mt-[30px]'>
                <a
                    type='button'
                    id='buttonHistoryComplete'
                    className='mx-auto flex text-white bg-[#FF0000] focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm mb-[43px] px-7 py-5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900'
                    style={{ fontSize: '22px' }}
                    href='/historia'
                >
                    {' '}
                    Veja toda a historia{' '}
                    <img
                        className='ml-2'
                        src={iconeseta}
                        style={{ display: 'inline' }}
                    />
                </a>
            </div>
        </div>
    )
}

export default Timeline
