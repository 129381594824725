import { React, Component, useState, useEffect } from 'react'
import 'flowbite'
import IconsCard from '../../components/cards/iconsCard.js'
import AppServices from '../../services/index'

const Gallery = () => {
  const [medias, setMedias] = useState([])

  useEffect(() => {
    AppServices()
      .getMedias()
      .then((res) => {
        setMedias(res.data.data.data)
      })
  }, [])
  return (
    <div className='mt-[40px] md:mx-[75px] mb-[75px] grid grid-cols-1 justify-items-center md:grid-cols-4 lg:grid-cols-4 2xl:grid-cols-4 gap-8'>
      {medias.map((media, index) => (
        <div>
          <a href={media.url ? media.url : '#'} target="_blank">
            <IconsCard
              img={`${process.env.REACT_APP_STORAGE_SERVER}/${media.src}`}
            />
          </a>
        </div>
      ))}
    </div>
  )
}
export default Gallery
