import { React } from 'react'
import 'flowbite'
import { Link } from 'react-router-dom'
import './iconsCards.css'
const IconsCard = (props) => {
  let img = props.img
  return (
    <div className='rounded-lg hover-icons-cards' style={{ minHeight: '40px' }}>
      <img src={img}  />
    </div>
  )
}
export default IconsCard
