import React from 'react'
import SubNavbar from '../../components/subnavbar'
import img from '../../_assets/img/subnovbar/galeria.png'
import GalleryCards from '../../components/gallery'

const Gallery = () => {
  return (
    <div>
      <SubNavbar imgNavbar={img} title='Galeria' />
      <div className='container mx-auto mb-2'>
        <GalleryCards />
      </div>
    </div>
  )
}

export default Gallery
