import React, { useState, useEffect } from 'react'
import 'flowbite'
import ImgCard from '../../components/cards/img.js'
import AppServices from '../../services/index'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const Gallery = () => {
  const [dataList, setDataList] = useState([])
  const [imageGalery, setImageGalery] = useState([])
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [perPage, setPerPage] = useState(6)
  const [disableNext, setDisableNext] = useState(false)
  const [disablePrev, setDisablePrev] = useState(true)

  useEffect(() => {
    AppServices()
      .getImagesGalery()
      .then((res) => {
        setImageGalery(res.data.data.data)
      })
  }, [])

  useEffect(() => {
    setDataList(imageGalery)
  }, [imageGalery])

  useEffect(() => {
    setCount(Math.ceil(dataList.length / perPage))
  }, [dataList, perPage])

  useEffect(() => {
    if (page === count || (page === 1 && count === 0)) {
      setDisableNext(true)
    } else {
      setDisableNext(false)
    }

    if (page === 1) {
      setDisablePrev(true)
    } else {
      setDisablePrev(false)
    }
  }, [count, page, disableNext, disablePrev])

  const paginate = (array, perPage, page) => {
    return array.slice((page - 1) * perPage, page * perPage)
  }

  const handleNext = () => {
    setPage(page + 1)
  }

  const handlePrev = () => {
    setPage(page - 1)
  }

  return (
    <>
      <div className=' mx-auto mt-[5px]  grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3  gap-2 mb-8'>
        {paginate(dataList, perPage, page).map((image, index) => (
            <a href={image.url}target='_blank' className=' mx-auto p-2' style={{position: 'relative'}}>
              <p style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '10', color: '#fff'}} className="tusker_grotesk_6500_medium text-[40px] text-center">{image.title}</p>
              <ImgCard img={image.src} key={index} />
            </a>
        ))}
      </div>

      <div className='w-full flex flex-row justify-end'>
        <button
          className={`${
            !disablePrev ? 'bg-[#4FB3F9]' : 'bg-[#ECF2FE]'
          } mx-[10px]`}
          onClick={() => handlePrev()}
          disabled={disablePrev}
        >
          <div
            className={`${disablePrev ? 'text-[#4FB3F9]' : 'text-[#ECF2FE]'}`}
            alt=''
          >
            <svg
              width='56'
              height='56'
              viewBox='0 0 56 56'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M36 27H23.83L29.42 21.41L28 20L20 28L28 36L29.41 34.59L23.83 29H36V27Z'
                fill={`${disablePrev ? '#4FB3F9' : '#ECF2FE'}`}
              />
            </svg>
          </div>
        </button>
        <button
          className={`${
            !disableNext ? 'bg-[#4FB3F9]' : 'bg-[#ECF2FE]'
          } mx-[10px]`}
          onClick={() => handleNext()}
          disabled={disableNext}
        >
          <svg
            width='56'
            height='56'
            viewBox='0 0 56 56'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M28 20L26.59 21.41L32.17 27H20V29H32.17L26.59 34.59L28 36L36 28L28 20Z'
              fill={`${disableNext ? '#4FB3F9' : '#ECF2FE'}`}
            />
          </svg>
        </button>
      </div>
    </>
  )
}
export default Gallery
