import { React } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import 'flowbite'
import Home from '../features/homepage'
import Media from '../features/media'
import ContactUs from '../features/contact_us'
import Gallery from '../features/image_gallery'
import Proposals from '../features/proposals'
import ImWithPaulo from '../features/im_with_paulo'
import News from '../features/news'
import WhatDidHeDo from '../features/what_did_he_do'
import History from '../features/history'
import Timeline from '../components/timeline/index'
import Novelty from '../features/novelty/index'
const Body = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home />}></Route>
                <Route path='/noticias' element={<News />}></Route>
                <Route path='/tocomopaulo' element={<ImWithPaulo />}></Route>
                <Route path='/oqueelefez' element={<WhatDidHeDo />}></Route>
                <Route path='/historia' element={<History />}></Route>
                <Route path='/novidade/:slug' element={<Novelty />}></Route>
                <Route path='/proposta' element={<Proposals />}></Route>
                <Route path='/galeria' element={<Gallery />}></Route>
                <Route path='/faleconosco' element={<ContactUs />}></Route>
                <Route path='/midias' element={<Media />}></Route>
                <Route path='/linha-do-tempo' element={<Timeline />}></Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Body
