import api from '../api'

export default function AppServices() {
    const getSocialNetworks = async () => {
        const res = await api.get('social_links')

        return res
    }

    const getProporsals = async () => {
        const res = await api.get('proposals')

        return res
    }

    const getImagesGalery = async () => {
        const res = await api.get('gallery_image')

        return res
    }

    const storeContactUs = async (queryParams) => {
        const res = await api.post('contact_us', queryParams)
        return res
    }

    const getNews = async () => {
        const res = await api.get('news')
        return res
    }

    const getNovelty = async (slug) => {
        const res = await api.get(`news/${slug}`)

        return res
    }

    const getMedias = async () => {
        const res = await api.get('media')
        return res
    }

    const storeLead = async (queryParams) => {
        const res = await api.post('lead_capture', queryParams)
        return res
    }

    const getTimeLine = async () => {
        const res = await api.get('timeline')
        return res
    }

    const getHistory = async () => {
        const res = await api.get('story')
        return res
    }

    return {
        getSocialNetworks,
        getProporsals,
        getImagesGalery,
        storeContactUs,
        getNews,
        getMedias,
        storeLead,
        getTimeLine,
        getHistory,
        getNovelty,
    }
}
