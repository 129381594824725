import { React, useState } from 'react'
import 'flowbite'
import logo_principal from '../../_assets/img/logo/logo_principal.png'
import './navbar.css'
import Social from '../social'

const Navbar = () => {
    const [expand, setExpand] = useState(false)
    const [hover, setHover] = useState(false)
    return (
        <nav className='w-full border-gray-200 px-[30px] dark:bg-gray-900 bg-[#701BB5]'>
            <div className='max-w-[1440px] w-full flex justify-between lg:justify-center lg:items-center mx-auto'>
                <a href='/' className='flex items-center'>
                    <img
                        src={logo_principal}
                        className='xl:ml-[104.14px] m-0 p-0 max-w-[200px] h-[100%]'
                        alt='Logo Principal Dantas'
                    />
                </a>

                <button
                    className='inline-flex items-center p-2 ml-3 bg-[#701BB5] text-sm lg:hidden focus:outline-none'
                    onClick={() => {
                        setExpand(!expand)
                    }}
                >
                    <span className='sr-only'>Open main menu</span>
                    <svg
                        className='w-6 h-6 z-[1000]'
                        aria-hidden='true'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                        style={{
                            color: 'white',
                        }}
                    >
                        {/* ${expand ? 'translate-x-0' : 'translate-x-full'} */}
                        <path
                            fillRule='evenodd'
                            d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
                            clipRule='evenodd'
                        ></path>
                    </svg>
                </button>

                <div
                    className={`${
                        expand ? 'block' : 'hidden' 
                    } mx-auto  w-[278px] h-[100vh] fixed lg:hidden z-[100] top-0 right-0 border-none bg-[#701BB5] transition ease-in-out duration-1000`}
                    id='navbar-default'
                >
                    <ul className='flex flex-col pl-4 pb-[50px] mt-[91px] bg-[#701BB5] gap-[26px] text-sm font-medium'>
                        <li>
                            <a
                                href='/noticias'
                                className='hover-menu py-2 pr-4 pl-3 text-white rounded md:bg-transparent  md:p-0 dark:text-white'
                                aria-current='page'
                            >
                                Notícias
                            </a>
                        </li>
                        <li>
                            <a
                                href='/tocomopaulo'
                                className='hover-menu py-2 pr-4 pl-3 text-white rounded md:bg-transparent  md:p-0 dark:text-white'
                            >
                                Tô com o Paulo
                            </a>
                        </li>
                        {/*
                        <li>
                            <details className='duration-300 hover-menu'>
                                <summary className='flex flex-row bg-[#701BB5] px-5 cursor-pointer pl-3 text-white rounded dark:text-white hover-menu'>
                                    <span className='hover-menu'>Biografia</span>
                                    <svg
                                        className='ml-1 w-5 h-5'
                                        aria-hidden='true'
                                        fill='currentColor'
                                        viewBox='0 0 20 20'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <path
                                            fillRule='evenodd'
                                            d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                                            clipRule='evenodd'
                                        ></path>
                                    </svg>
                                </summary>

                                <ul className=''>
                                    <li className='pt-[15px]'>
                                        <a
                                            href='/historia'
                                            className='hover-menu py-2 pr-4 pl-3 text-[#AC8BFF] rounded md:bg-transparent  md:p-0 dark:text-white'
                                        >
                                            História
                                        </a>
                                    </li>
                                    <li className='pt-[15px]'>
                                        <a
                                            href='/oqueelefez'
                                            className='hover-menu py-2 pr-4 pl-3 text-[#AC8BFF] rounded md:bg-transparent  md:p-0 dark:text-white'
                                        >
                                            O que ele fez
                                        </a>
                                    </li>
                                </ul>
                            </details>
                        </li>
                       */}
                        <li>
                            <a
                                href='/historia'
                                className='hover-menu py-2 pr-4 pl-3 text-white rounded md:bg-transparent  md:p-0 dark:text-white'
                            >
                                História
                            </a>
                        </li>
                        <li>
                            <a
                                href='/proposta'
                                className='hover-menu py-2 pr-4 pl-3 text-white rounded md:bg-transparent  md:p-0 dark:text-white'
                            >
                                Propostas
                            </a>
                        </li>
                        <li>
                            <a
                                href='/galeria'
                                className='hover-menu py-2 pr-4 pl-3 text-white rounded md:bg-transparent  md:p-0 dark:text-white'
                            >
                                Galeria de imagens
                            </a>
                        </li>
                    </ul>
                    <ul className='h-[329px] flex flex-col pt-[30px] bg-[#F4901E] gap-[26px] text-sm font-medium'>
                        <li className='pl-4'>
                            <a
                                href='/faleconosco'
                                className='hover-menu py-2 pr-4 pl-3 text-white rounded md:bg-transparent  md:p-0 dark:text-white'
                            >
                                Fale conosco
                            </a>
                        </li>
                        <li className='pl-4'>
                            <a
                                href='/midias'
                                className='hover-menu py-2 pr-4 pl-3 text-white rounded md:bg-transparent  md:p-0 dark:text-white'
                            >
                                Mídias
                            </a>
                        </li>
                        <li className='flex items-center justify-center'>
                            <Social
                                color='rgba(255, 255, 255, 0.2)'
                                widthButton='34px'
                                heightButton='34px'
                                widthInstagram='17px'
                                heightInstagram='17px'
                                widthFacebook='10.2px'
                                heightFacebook='19.55px'
                                widthTwitter='16.66px'
                                heightTwitter='13.26px'
                                widthYoutube='18.7px'
                                heightYoutube='12.75px'
                            />
                        </li>
                    </ul>
                </div>

                <div
                    className='hidden w-full lg:block xl:ml-[40px] md:w-auto'
                    id='navbar-default'
                >
                    <ul className='xl:mr-[88px] flex flex-col p-4 mt-4 bg-[#701BB5] rounded-lg border border-gray-100 md:flex-row gap-[18px] md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-[#701BB5] dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700'>
                        <li>
                            <a
                                href='/noticias'
                                className='hover-menu py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent  md:p-0 dark:text-white'
                                aria-current='page'
                            >
                                Notícias
                            </a>
                        </li>
                        <li>
                            <a
                                href='/tocomopaulo'
                                className='hover-menu py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0  md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'
                            >
                                Tô com o Paulo
                            </a>
                        </li>
                        {
                            /*
                        <li>
                            <button
                                className='hover-menu flex justify-between items-center py-2 pr-4 pl-3 w-full font-medium text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 md:w-auto dark:text-gray-400 dark:hover:text-white dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent'
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                            >
                                Biografia{' '}
                                <svg
                                    className='ml-1 w-5 h-5'
                                    aria-hidden='true'
                                    fill='currentColor'
                                    viewBox='0 0 20 20'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        fillRule='evenodd'
                                        d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                                        clipRule='evenodd'
                                    ></path>
                                </svg>
                            </button>
                            <div
                                id='dropdownNavbar'
                                className={`${
                                    hover ? 'block' : 'hidden'
                                } absolute z-10 w-44 font-normal bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600`}
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                            >
                                <ul
                                    className='text-sm text-white-700 dark:text-gray-400'
                                    aria-labelledby='dropdownLargeButton'
                                >
                                    <li className='py-1 px-[5px]'>
                                        <a
                                            href='/historia'
                                            className='hover-menu w-full px-4 dark:hover:text-white'
                                        >
                                            História
                                        </a>
                                    </li>
                                    <li className='py-1 px-[5px]'>
                                        <a
                                            href='/oqueelefez'
                                            className='hover-menu w-full px-4 dark:hover:text-white'
                                        >
                                            O que ele fez
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        */
                    }
                        <li>
                            <a
                                href='/historia'
                                className='hover-menu py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0  md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'
                            >
                                História
                            </a>
                        </li>
                        <li>
                            <a
                                href='/proposta'
                                className='hover-menu py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0  md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'
                            >
                                Propostas
                            </a>
                        </li>
                        <li>
                            <a
                                href='/galeria'
                                className='hover-menu py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0  md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'
                            >
                                Galeria de imagens
                            </a>
                        </li>
                        <li>
                            <a
                                href='/faleconosco'
                                className='hover-menu py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'
                            >
                                Fale conosco
                            </a>
                        </li>
                        <li>
                            <a
                                href='/midias'
                                className='hover-menu py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0  md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'
                            >
                                Mídias
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
