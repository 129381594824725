import React from 'react'
import GalleryCardIcon from '../../components/GalleryCardIcon'
import SubNavbar from '../../components/subnavbar'
import img from '../../_assets/img/subnovbar/midias.jpg'

const Media = () => {
  return (
    <div>
      <SubNavbar imgNavbar={img} title='Mídia' />
      <div className='container mx-auto'>
        <GalleryCardIcon />
      </div>
    </div>
  )
}

export default Media
