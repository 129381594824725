import { React } from 'react'
import 'flowbite'
import './img.css'

const ImgCard = (props) => {
  let img = props.img
  return (
    <div className="cardImageGallery">
      <img className="mx-auto" src={`${process.env.REACT_APP_STORAGE_SERVER}/${img}`} />
    </div>
  )
}
export default ImgCard
