import React, { useState, useEffect } from 'react'
import SubNavbar from '../../components/subnavbar'
import dantas from '../../_assets/img/formulario/Paulo _Nova 1.png'
import next from '../../_assets/icons/arrowNextButton.svg'
import img from '../../_assets/img/subnovbar/fale_conosco.png'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useFormik } from 'formik'

import { validationSchema } from './validations'
import AppServices from '../../services/index'

import './im_with_paulo.css'

const ImWithPaulo = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [whatsapp, setWhatsapp] = useState('')

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      whatsapp: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      AppServices()
        .storeLead({
          name: values.name,
          email: values.email,
          whatsapp: values.whatsapp,
        })
        .then((res) => {
          if (res.data.status) {
            //
            formik.handleReset()
            setName('')
            setEmail('')
            setWhatsapp('')
            toast(res.data.message)
          }
        })
    },
  })

  return (
    <div className=' bg-[#6728AE] p-0 m-0'>
      <SubNavbar imgNavbar={img} title='Tô com Paulo' />
      <ToastContainer
        position='top-right'
        autoClose={5000}
        closeOnClick
        pauseOnHover
        theme='dark'
        type='success'
      />
      <div className='mx-[18px] sm:mx-[50px ] md:mx-[100px] lg:mx-auto overflow-hidden bg-[#6728AE]'>
        <div className='flex flex-col lg:grid lg:grid-cols-6 lg:gap-4 max-w-[1440px] mx-auto'>
          <div className='lg:col-start-1 lg:col-end-4 lg:mx-auto'>
            <div>
              <div className=':lg:ml-[88px] pb-[24px] pt-[34px] text-left'>
                <h4 className='leading-[39px]' id='titleFormulario'>
                  {' '}
                  <span style={{ color: '#FFFFFF' }}>
                    Receba o conteúdo
                    <br />
                    do
                  </span>{' '}
                  <span style={{ color: '#F4901E' }}>Paulo</span>
                </h4>
              </div>
              <form
                className='ml:w-full lg:max-w-[536px] lg:ml-[0]'
                onSubmit={formik.handleSubmit}
              >
                <div className='my-8 '>
                  <label
                    htmlFor='email'
                    className='block mb-2 text-sm font-medium text-white dark:text-gray-300'
                  >
                    Nome
                  </label>
                  <input
                    type='text'
                    id='name'
                    className='shadow-sm bg-gray-50 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light'
                    placeholder='Digite seu nome completo'
                    onChange={(e) => {
                      setName(e.target.value)
                      formik.handleChange(e)
                    }}
                    value={name}
                  />
                  <span
                    style={{
                      display:
                        formik.touched.name && formik.errors.name ? '' : 'none',
                    }}
                  >
                    {formik.touched.name && formik.errors.name}
                  </span>
                </div>
                <div className='mb-6'>
                  <label
                    htmlFor='email'
                    className='block  text-sm font-medium text-white dark:text-gray-300'
                  >
                    Email
                  </label>
                  <input
                    type='text'
                    id='email'
                    className='shadow-sm bg-gray-50 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light'
                    placeholder='Digite seu email'
                    onChange={(e) => {
                      setEmail(e.target.value)
                      formik.handleChange(e)
                    }}
                    value={email}
                  />
                  <span
                    style={{
                      display:
                        formik.touched.email && formik.errors.email
                          ? ''
                          : 'none',
                    }}
                  >
                    {formik.touched.email && formik.errors.email}
                  </span>
                </div>
                <div className='mb-6'>
                  <label
                    htmlFor='whatsapp'
                    className='block mb-2 text-sm font-medium text-white dark:text-gray-300'
                  >
                    Telefone (whatsapp)
                  </label>
                  <input
                    type='text'
                    id='whatsapp'
                    className='shadow-sm bg-gray-50 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light'
                    placeholder='Digite seu numero'
                    onChange={(e) => {
                      setWhatsapp(e.target.value)
                      formik.handleChange(e)
                    }}
                    value={whatsapp}
                  />
                  <span
                    style={{
                      display:
                        formik.touched.whatsapp && formik.errors.whatsapp
                          ? ''
                          : 'none',
                    }}
                  >
                    {formik.touched.whatsapp && formik.errors.whatsapp}
                  </span>
                </div>
                <button
                  type='submit'
                  id="buttonSubmitImWithPaulo"
                  className='flex flex-row justify-center items-center gap-5 w-full h-[72px] mb-[50px] md:w-[171px] text-white bg-[#F4901E] text-[22px]
                focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center'>
                  Enviar
                  <img
                    className='ml-[2px]'
                    src={next}
                    style={{ display: 'inline' }}
                  />
                </button>
              </form>
            </div>
          </div>

          <div className='relative bottom-0 hidden  max-w-[752px] xl:block lg:block pr-[26px] col-end-7 col-span-3 mr-[2px] bg-[url(../../_assets/img/formulario/coracoes.png)]'>
            <img
              src={dantas}
              className='absolute bottom-0 hidden xl:block lg:block mt-[18px] max-h-[500px]'
            />
          </div>
        </div>
        <hr className='border-gray-200 sm:mx-auto dark:border-gray-700 ' />
        <div id='fullColor'>sd</div>
      </div>
    </div>
  )
}

export default ImWithPaulo
