/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react'
import banner_01 from '../../_assets/img/banners/banner_01.png'
import { Carousel } from 'flowbite-react'

const Banner = () => {
  const [builded, setBuilded] = useState(false)
  useEffect(() => {
    let element = document.getElementById('ban')
    let chil = element.firstElementChild
    let subChil = chil.firstElementChild
    chil.classList.remove('rounded-lg')
    subChil.classList.remove('cursor-grab')
    console.log(chil.classList)
  }, [builded])

  return (
    <div className='h-[220px] sm:h-[300px]  md:h-[400px] lg:h-[800px]'>
      {/*
            <Carousel id='ban' onLoad={() => setBuilded(true)}>
        <img
          src={banner_01}
          className='absolute block w-full lg:mt-[-70px] '
          alt='...'
        />
      </Carousel>
      */}

  <div class="relative h-full w-full" data-testid="carousel" id="ban">
    <div class="flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth snap-x indiana-scroll-container indiana-scroll-container--hide-scrollbars">
        <div class="w-full flex-shrink-0 transform snap-center" data-active="true" data-testid="carousel-item">
          <img src={banner_01} class="absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2 absolute block w-full lg:mt-[-70px] " alt="..."/>
        </div>
    </div>
    <div class="absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3"><button class="h-[2px] w-6 bg-white bg-[#f49011e] dark:bg-gray-800" data-testid="carousel-indicator"></button></div>
    <div class="absolute bottom-5 left-0 flex h-full items-center justify-center px-4 focus:outline-none h-[47px]" >
        <button class="group" data-testid="carousel-left-control" type="button">
          <span class="inline-flex h-8 w-8 items-center justify-center rounded-full group-hover:bg-white/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70 sm:h-10 sm:w-10">
              <svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 24 24" class="h-5 w-5 text-white dark:text-gray-800 sm:h-6 sm:w-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
              </svg>
          </span>
        </button>
    </div>
    <div class="absolute bottom-5 left-10 flex h-full items-center justify-center px-4 focus:outline-none h-[47px]">
        <button class="group" data-testid="carousel-right-control" type="button">
          <span class="inline-flex h-8 w-8 items-center justify-center rounded-full group-hover:bg-white/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70 sm:h-10 sm:w-10">
              <svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 24 24" class="h-5 w-5 text-white dark:text-gray-800 sm:h-6 sm:w-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
              </svg>
          </span>
        </button>
    </div>
  </div>
</div>
  )
}
export default Banner
