import React from 'react'
import SubNavbar from '../../components/subnavbar'
import Timeline from '../../components/timeline'
import img from '../../_assets/img/subnovbar/galeria.png'

const WhatDidHeDo = () => {
  return (
    <div>
      <SubNavbar imgNavbar={img} title='O que ele fez?' />
      <div className='container mx-auto'>
        <Timeline />
      </div>
    </div>
  )
}

export default WhatDidHeDo
